<template>
  <div :class="{ comments: !isDarkMode, 'comments_dark dark-div rounded': isDarkMode }">
    <div :class="comments_wrapper_classes" id="comment-list">
      <div
        :class="{ comment: !isDarkMode, 'comment bg-dark mt-3 text-light': isDarkMode }"
        v-bind:key="item"
        v-for="item in Object.keys(comments)"
      >
        <div class="tag-reply">
          <h6 class="tag" v-if="item !== 'null'">{{ item }}</h6>
          <!-- <div class="replyNew">
            <button class="reply---button" @click="onComment(item)"><i class="fa fa-paper-plane"></i> Reply</button>
          </div> -->
        </div>
        <single-comment
          :tag="tag"
          v-for="comment in comments[item]"
          :comment="comment"
          :key="comment.id"
        ></single-comment>
      </div>
    </div>
    <hr />
    <div :class="{ reply: !isDarkMode, 'reply bg-dark': isDarkMode }">
      <div class="tag">
        <strong v-if="tag"
          >{{ tag.questionText }} {{ tag.text }}
          <b-button :variant="isDarkMode ? 'outline-danger' : 'danger'" @click="onClick"> X </b-button></strong
        >
      </div>
      <textarea
        type="text"
        id="myText"
        autofocus
        v-model="reply"
        :class="{ 'reply--text': !isDarkMode, 'reply--text text-light': isDarkMode }"
        placeholder="Leave a comment..."
        required
      />

      <button class="reply--button box-shadow2" @click.prevent="submitComment"
        ><i class="fa fa-paper-plane"></i> Send</button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import singleComment from './singleComment.vue';

export default {
  name: 'comments',
  components: {
    singleComment
  },
  data() {
    return {
      reply: ''
      // comment: ''
    };
  },
  mounted() {
    const CommentListElement = document.getElementById('comment-list');
    if (CommentListElement) {
      setTimeout(() => {
        CommentListElement.scroll({ top: CommentListElement.scrollHeight, behavior: 'smooth' });
      }, 1000);
    }
  },

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('comment', ['postReportComment', 'postNoteComment', 'postEventComment']),
    onClick() {
      this.$emit('close');
    },
    submitComment() {
      if (this.reply !== '') {
        this.$emit('submit-comment', this.reply);
        // this.$emit('submit-comment-reply', this.reply, this.tag);
        this.reply = '';
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    }
    // onComment(item) {
    //   this.tag = { text: item }; // eslint-disable-line
    //   document.getElementById('myText').focus();
    // }
  },
  props: ['comments', 'comments_wrapper_classes', 'tag']
};
</script>

<style scoped>
.tag {
  margin-left: 20px;
  color: rgb(42, 85, 226);
}
.tag-reply {
  display: flex;
}
.btn {
  float: right;
  border-radius: 999px;
  font-weight: bold;
  font-size: 15px;
  margin: 5px;
}
.comments {
  margin-top: 20px;
  padding: 20px;
  padding-top: 0;
}
.comments_dark {
  margin-top: 20px;
  padding: 20px;
  padding-top: 0;
}
.comments-wrapper {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #fff;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
/* Reply component */
.reply {
  display: flex;
  position: relative;
  align-items: center;
  background-color: #ebebeb;
  border-radius: 30px;
  padding: 5px 10px;
  overflow: hidden;
}
.reply .reply--text {
  min-height: 80px;
  padding: 10px 10px 10px 55px;
  margin-right: 10px;
  border: 0;
  width: 100%;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
}
.reply input.reply--text:valid {
  margin-right: 71px;
}
.reply input.reply--text:valid + .reply--button {
  right: 10px;
}
.reply .reply--button {
  /* position: absolute; */
  right: -100px;
  border: 1px solid #2a629c;
  background-color: transparent;
  color: #2a629c;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 30px;
  outline: 0;
}
.reply .reply--button:hover {
  color: #fff;
  background-color: #2a629c;
}
.reply .reply--button:focus,
.reply .reply--button:active {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.comment {
  display: flow-root;
  padding: 10px;
  margin-bottom: 30px;
  color: #333;
  background-color: #f2f2f2;
  border-radius: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}
.comment .text {
  text-align: left;
  margin-left: 5px;
  max-width: 72rem;
}
.comment .text span {
  margin-left: 5px;
}
.comment .text .username {
  font-weight: bold;
  color: rgb(236, 124, 72);
}
.replyNew {
  display: flex;
  position: relative;
  align-items: center;
  /* background-color: #ebebeb; */
  border-radius: 30px;
  padding: 5px 10px;
  overflow: hidden;
}
.replyNew .reply--text {
  min-height: 80px;
  padding: 10px 10px 10px 55px;
  margin-right: 10px;
  border: 0;
  color: #333;
  width: 100%;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
}
.replyNew input.reply--text:valid {
  margin-right: 71px;
}
.replyNew input.reply--text:valid + .reply--button {
  right: 10px;
}
.replyNew .reply---button {
  /* position: absolute; */
  /* right: -100px; */
  border: 1px solid #2a629c;
  background-color: transparent;
  color: #2a629c;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 30px;
  outline: 0;
}
.replyNew .reply---button:hover {
  color: #fff;
  background-color: #2a629c;
}
.replyNew .reply---button:focus,
.replyNew .reply---button:active {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
