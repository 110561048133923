<template>
  <div class="page-class page-setting-form">
    <div class="comments-outside">
      <div class="comments-header dark-div"> </div>
      <comment-box
        :tag="tag"
        :comments_wrapper_classes="['custom-scrollbar', 'comments-wrapper']"
        :comments="comments"
        @submit-comment="submitComment"
        @close="onClose"
      ></comment-box>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { groupBy } from 'lodash';
import { mapState, mapActions } from 'vuex';
import CommentBox from '../../components/CommentBox.vue';

export default {
  name: 'app',
  components: {
    CommentBox
  },
  data() {
    return {
      comment: '',
      comments: []
    };
  },
  computed: {
    title() {
      return 'Comment';
    },
    ...mapState('report', ['report', 'loading']),
    ...mapState('notes', ['note', 'loading']),
    ...mapState('photoSharing', ['eventphoto', 'loading']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('comment', ['postReportComment', 'postNoteComment', 'postEventComment']),
    ...mapActions('report', ['getOne']),
    ...mapActions('notes', ['getOne']),
    ...mapActions('photoSharing', ['getOne']),
    onClose() {
      this.$emit('remove');
    },
    submitComment(reply) {
      const newComment = {
        body: reply,
        submittedByName: this.user.name,
        submittedBy: this.user.id,
        tag: this.tag ? (this.tag.text ? this.tag.text : this.tag.questionText) : null // eslint-disable-line
      };
      if (this.reportId) {
        this.postReportComment({
          reportId: this.reportId,
          comment: newComment,
          router
        });
      } else if (this.noteId) {
        this.postNoteComment({
          noteId: this.noteId,
          comment: newComment,
          router
        });
      } else {
        this.postEventComment({
          eventId: this.eventId,
          comment: newComment,
          router
        });
      }
      this.$emit('remove');
      this.comments.push(newComment);
    }
    // submitCommentReply(reply, tag) {
    //   const newComment = {
    //     body: reply,
    //     submittedByName: this.user.name,
    //     submittedBy: this.user.id,
    //     tag: tag.text
    //   };
    //   if (this.reportId) {
    //     this.postReportComment({
    //       reportId: this.reportId,
    //       comment: newComment,
    //       router
    //     });
    //   } else {
    //     this.postNoteComment({
    //       noteId: this.noteId,
    //       comment: newComment,
    //       router
    //     });
    //   }
    //   this.comments.push(newComment);
    //   this.$emit('remove');
    // }
  },
  props: ['reportId', 'noteId', 'eventId', 'tag'],
  watch: {
    report(newValue) {
      this.comments = groupBy(newValue.comments, 'tag');
    },
    note(newValue) {
      this.comments = groupBy(newValue.comments, 'tag');
    },
    eventphoto(newValue) {
      this.comments = groupBy(newValue.comments, 'tag');
    }
  }
};
</script>

<style>
a {
  text-decoration: none;
}
.comments-outside {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  max-width: auto;
}
.comments-header {
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: #333;
  min-height: 50px;
  font-size: 20px;
}
.comments-header .comments-stats span {
  margin-left: 10px;
}
.post-owner {
  display: flex;
  align-items: center;
}
.post-owner .username {
  margin-left: 5px;
}
.post-owner .username > a {
  color: #333;
}
</style>
