<script src="//unpkg.com/v-viewer/dist/v-viewer.js"></script>

<template>
  <div>
    <div class="box-shadow2 border-top">
      <div
        :class="{
          'table-row table-row-header': !isDarkMode,
          'table-row-dark dark-div': isDarkMode
        }"
      >
        <div
          v-for="(column, index) in columns"
          :key="index"
          :class="{
            ...column.class,
            'table-column': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-first': index === 0,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          {{ column.headerText }}
        </div>
      </div>

      <div
        :class="{
          'table-row ': !isDarkMode,
          'table-row-dark': isDarkMode
        }"
        v-for="row in rows"
        :key="row.id"
      >
        <div
          v-for="(column, index) in columns"
          :key="column.id"
          :class="{
            ...column.class,
            'table-column ': !isDarkMode,
            'table-column-dark': isDarkMode,
            'table-column-last-odd': columns.length === index + 1 && (columns.length - 1) % 2 === 1
          }"
          :style="columnStyle(column)"
        >
          <slot v-if="column.slotKey" :name="column.slotKey" v-bind:row="row"></slot>
          <div v-if="column.htmlKey" v-html="row[column.htmlKey]"></div>
          <span
            v-if="column.textKey && column.textKey != 'picture'"
            :class="row.isCritical && row.score === 0 ? 'red-alert' : 'span-text'"
            >{{ row[column.textKey] }}</span
          >
          <div
            v-if="column.type === 'image' && row[column.textKey] && typeof row[column.textKey] === 'string'"
            class="text-center"
          >
            <b-link :href="row[column.textKey]" target="_blank"
              >{{ row[picture] }}<img center :src="row[column.textKey]" thunbnail fluid alt="No Image Available"
            /></b-link>
          </div>

          <!-- <div v-if="column.type === 'image' && row[column.textKey]" class="text-center"> -->
          <!-- <b-link :href="row[column.textKey][0]" target="_blank"
              >{{ row[picture] }}<img center :src="row[column.textKey][0]" thunbnail fluid alt="No Image Available"
            /></b-link> -->
          <!-- <b-button @click="downloadPhotos(row[column.textKey])">Download all </b-button> -->
          <!-- </div> -->
          <div v-viewer v-if="column.type === 'image' && row[column.textKey] && typeof row[column.textKey] != 'string'">
            <div>
              <template v-for="image in row[column.textKey]">
                <div :key="image" class="imageview">
                  <img :src="image" class="image" />
                </div>
              </template>
            </div>
          </div>
          <link href="//unpkg.com/viewerjs/dist/viewer.css" rel="stylesheet" />

          <div v-if="column.type === 'functions'" class="text-center">
            <b-button-group size="sm">
              <b-button
                v-if="column.functions.comment"
                size="sm"
                :variant="isDarkMode ? 'outline-light' : 'secondary'"
                @click="clickComment(row)"
              >
                <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Comment
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueViewer from 'v-viewer';
import Vue from 'vue';
import { mapState } from 'vuex';

Vue.use(VueViewer);

export default {
  name: 'ReportCollapsible',
  props: {
    data: Object,
    columns: Array,
    rows: Array
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode'])
  },
  data() {
    return {};
  },

  methods: {
    columnStyle(column) {
      return { width: column.width };
    },
    clickComment(row) {
      this.$emit('comment', { row });
    }
  }
};
</script>

<style scoped>
.question-text {
  font-size: 1.2rem;
  font-weight: bold;
}
img {
  width: 3em !important;
  height: 4.5em !important;
  transition: transform 0.2s;
}

/* img:hover {
  transform: scale(3.5);
} */
.btn-info {
  background-color: orange;
}
.btn-info:hover {
  background-color: orange;
}
.red-alert {
  color: #e05d5d;
}
</style>
