var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"box-shadow2 border-top"},[_c('div',{class:{
        'table-row table-row-header': !_vm.isDarkMode,
        'table-row-dark dark-div': _vm.isDarkMode
      }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:index,class:Object.assign({}, column.class,
          {'table-column': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-first': index === 0,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[_vm._v(" "+_vm._s(column.headerText)+" ")])}),0),_vm._l((_vm.rows),function(row){return _c('div',{key:row.id,class:{
        'table-row ': !_vm.isDarkMode,
        'table-row-dark': _vm.isDarkMode
      }},_vm._l((_vm.columns),function(column,index){return _c('div',{key:column.id,class:Object.assign({}, column.class,
          {'table-column ': !_vm.isDarkMode,
          'table-column-dark': _vm.isDarkMode,
          'table-column-last-odd': _vm.columns.length === index + 1 && (_vm.columns.length - 1) % 2 === 1}),style:(_vm.columnStyle(column))},[(column.slotKey)?_vm._t(column.slotKey,null,{"row":row}):_vm._e(),(column.htmlKey)?_c('div',{domProps:{"innerHTML":_vm._s(row[column.htmlKey])}}):_vm._e(),(column.textKey && column.textKey != 'picture')?_c('span',{class:row.isCritical && row.score === 0 ? 'red-alert' : 'span-text'},[_vm._v(_vm._s(row[column.textKey]))]):_vm._e(),(column.type === 'image' && row[column.textKey] && typeof row[column.textKey] === 'string')?_c('div',{staticClass:"text-center"},[_c('b-link',{attrs:{"href":row[column.textKey],"target":"_blank"}},[_vm._v(_vm._s(row[_vm.picture])),_c('img',{attrs:{"center":"","src":row[column.textKey],"thunbnail":"","fluid":"","alt":"No Image Available"}})])],1):_vm._e(),(column.type === 'image' && row[column.textKey] && typeof row[column.textKey] != 'string')?_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}]},[_c('div',[_vm._l((row[column.textKey]),function(image){return [_c('div',{key:image,staticClass:"imageview"},[_c('img',{staticClass:"image",attrs:{"src":image}})])]})],2)]):_vm._e(),_c('link',{attrs:{"href":"//unpkg.com/viewerjs/dist/viewer.css","rel":"stylesheet"}}),(column.type === 'functions')?_c('div',{staticClass:"text-center"},[_c('b-button-group',{attrs:{"size":"sm"}},[(column.functions.comment)?_c('b-button',{attrs:{"size":"sm","variant":_vm.isDarkMode ? 'outline-light' : 'secondary'},on:{"click":function($event){return _vm.clickComment(row)}}},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_vm._v("Comment ")],1):_vm._e()],1)],1):_vm._e()],2)}),0)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }