<template>
  <div>
    <div class="comment">
      <div class="text">
        <div>
          <span class="username">{{ comment.submittedByName }} :</span>
          <span>{{ comment.body }}</span>
          <!-- <span>{{ comment.imagePath }}</span> -->
          <div class="ctr" v-if="comment.imagePath">
            <b-link :href="comment.imagePath" target="_blank">
              <img center src="../assets/images/ImageIcon.png" thumbnail height="30" alt="" />
            </b-link>
          </div>
          <span class="commentTime"> {{ moment(comment.updatedAt).format('DD-MM-YYYY  hh:mm A') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'singleComment',
  props: ['comment'],
  computed: {
    moment: function () {
      return moment;
    }
  }
};
</script>

<style scoped>
/* Single-comment component */
.comment {
  display: flex;
  padding: 5px;
}
.ctr {
  border-radius: 10px;

  overflow: hidden;

  display: inline-flex;
  margin-left: 50px;
}
.comment .text {
  text-align: left;
  margin-left: 5px;
  max-width: 71rem;
}
.comment .text span {
  margin-left: 5px;
}
.comment .text .username {
  font-weight: bold;
  color: rgb(236, 124, 72);
}
.commentTime {
  font-size: 0.8rem;
  color: rgb(85, 118, 228);
  display: flex;
}
</style>
