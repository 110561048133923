<template>
  <div class="page-class page-setting-form">
    <h1 class="page-title text-center">{{ title }}</h1>

    <div>
      <div v-if="loading">
        <span class="spinner"></span>
      </div>
      <div v-if="!loading">
        <b-row>
          <b-col cols="12">
            <section>
              <report-collapsible
                v-if="note"
                :rows="noteData"
                :columns="columns"
                @comment="onComment"
              ></report-collapsible>
            </section>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="header"> <h4>Comments</h4></div>
    <comment @remove="onRemove" :tag="tag" :noteId="$route.params.id"> </comment>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ReportCollapsible from '../../components/ReportCollapsible.vue';
import Comment from '../Comment/Comment.vue';

export default {
  name: 'NoteView',
  components: {
    ReportCollapsible,
    Comment
  },
  data() {
    return {
      tag: null,
      columns: [
        {
          type: 'string',
          headerText: 'Subject',
          class: { 'text-center': true },
          textKey: 'subject',
          width: '30%'
        },
        {
          type: 'string',
          headerText: 'Content',
          class: { 'text-center': true },
          textKey: 'text',
          width: '35%'
        },
        {
          type: 'image',
          headerText: 'Image',
          class: { 'text-center': true },
          textKey: 'picture',
          width: '20%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '15%',
          functions: {
            comment: true
          }
        }
      ]
    };
  },
  async mounted() {
    this.getOne({
      query: {
        id: this.$route.params.id
      }
    });
  },
  computed: {
    title() {
      return 'Note';
    },
    ...mapGetters('alert', ['errorMessages']),
    ...mapState('notes', ['note', 'loading']),
    noteData() {
      return this.note.content.map(({ image, ...i }) => {
        return { ...i, picture: image, subject: this.note.subject };
      });
    }
  },
  methods: {
    ...mapActions('notes', ['getOne']),
    onRemove() {
      this.tag = null;
    },
    onComment(value) {
      this.tag = value.row;
      document.getElementById('myText').focus();
    }
  }
};
</script>

<style scoped>
.scrollbar-list {
  padding: 0 24px;
  font-size: 0.95rem;
  color: white;
  transition: background 0.2s, padding 0.2s;
  background-color: #4e5359;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
}
.is-active {
  background: #42464b !important;
}
.header {
  margin-top: 2rem;
}
</style>
